<template>

  <div>

    
    <div class="divider" v-if="title">
      {{ title }}
    </div>
        
    <div class="comments">



      <CommentForm
        :type="type"
        :content="content"        
        @created="comments_data?.items_comment?.unshift($event)"
        />
     

      <br>

      <div class="settings_comments" v-if="comments_data?.items_comment?.length">
        <div class="toggler" 
          v-if="type == 'diary' || type == 'week'" 
          >
          <UiToggle 
            v-model="byWeeks" 
            @update:modelValue="byWeeks = $event"
            :label="$t('diary_info_show_by_week')"
            />
        </div>

        <div class="sortb">
          <UiSort             
            @choose="chooseSort"
            :val="sort"    
            :options="[
              {id:'top', name: $t('comments_view_sort_by_popularity')},      
              {id:'desc', name: $t('comments_view_sort_by_date')},      
              {id:'asc', name: $t('comments_view_sort_by_oldest')},     
            ]"
            />
        </div>

      </div>

      <span ref="startofcomments"></span>
 
      <CommentItem 
        v-if="comments_data?.items_comment?.length"
        v-for="(opt, opt_key) in comments_data.items_comment"      
        :data="opt"
        :likes="comments_data?.addon?.likes"
        :is-liked="comments_data?.addon?.likes?.includes(opt.id)"
        :type="type"
        :content="content"
        :sort="sort"
        @remove="removeComment"
        @removereply="removeReply"
        />
        
      <div v-else class="empty_comments">
        Be the first to comment it
      </div>

 

      <client-only>
        <InfinityScroll 
          v-if="!is_loading && comments_data?.cnt > start + limit" 
          :is-loading="is_loading"
          ref="el_infinity_scroll"/>
      </client-only>
   
    </div>
 

  </div>

</template>


<script setup>
 
import { useIntersectionObserver } from '@vueuse/core'
import { watchArray } from '@vueuse/core'

const props = defineProps({
  type: {
    type: String,
    required: false
  },
  content: {
    type: Number
  },
  title: {
    type: String
  }
});

const route = useRoute()

const {$api, $ga} = useNuxtApp()
const el_infinity_scroll = ref(null)
const start = ref(0)
const limit = ref(20)
const cnt = ref(0)
const sort = ref(useAuth().getPreset('week_comment_sort') || 'top')
const byWeeks = ref(false)
const preloadLnk = ref(route.query?.lnk || null)
const startofcomments = ref(null)

const clearPagination = function(){     
  start.value = 0;
}

const chooseSort = function(selected_sort){   
  sort.value = selected_sort;
  comments_data.value.items_comment = [];
  clearPagination();  
}

const removeComment = function(id){
  comments_data.value.items_comment = comments_data.value.items_comment.filter((opt) => opt.id != id);
}

const removeReply = function(comment_id, reply_id){
  comments_data.value.items_comment = comments_data.value.items_comment.map((opt) => {
    if(opt.id == comment_id){
      opt.replies.items_comment = opt.replies.items_comment.filter((opt) => opt.id != reply_id);
    }
    return opt;
  });
}

const loadData = async function() {     
  //console.log(byWeeks.value)
  const response = await $api.getComments(
    props.content, 
    (byWeeks.value ? 'week' : props.type),   
    start.value, 
    limit.value,
    sort.value,
    preloadLnk.value
  )  
  return response;  
}


const { pending: is_loading,  data: comments_data } = await useLazyAsyncData('comments_data_' + props.content + props.type, async () => await loadData())


watchArray([start, sort, byWeeks], async ([new_start, new_sort, new_byWeeks], added, removed) => {
  console.log('widget comments. watchArray: start, sort, byWeeks');
  is_loading.value = true;
  var dt = await loadData();
  if(new_start == 0) comments_data.value.items_comment = [];  
  comments_data.value.items_comment = [...comments_data.value.items_comment, ...dt.items_comment];
  is_loading.value = false;

}, {deep: true});


// watchArray([props], async ([new_props], added, removed) => {
//   console.log('changed props comments!!!!!!!!!!!!!!');
//   console.log(new_props);

  
//   if(byWeeks.value){
//     clearPagination();
//     var dt = await loadData();
//     comments_data.value.items_comment = dt.items_comment;
//   }
// }, {immediate: true, deep: true});



// watchArray([props.content, props.type], async ([new_props], added, removed) => {
//   console.log('widget comments. watchArray: props');
//   // console.log('changed props comments!!!!!!!!!!!!!!');
//   // console.log('new_content');
//   // console.log(new_props);
//   // props.content = new_content;
//   // props.type = new_type;
//   if(comments_data.value?.addon?.likes) comments_data.value.addon.likes = [];
//   if(comments_data.value?.items_comment) comments_data.value.items_comment = [];
//   clearPagination();
//   var dt = await loadData();
//   comments_data.value.items_comment = dt.items_comment;
//   comments_data.value.addon = dt.addon;
// }, {deep: true});
 

// watchArray([props], async ([new_props], added, removed) => {
//   console.log('widget comments. watchArray: props');
//   // console.log('changed props comments!!!!!!!!!!!!!!');
//   // console.log('new_content');
//   // console.log(new_props);
//   // props.content = new_content;
//   // props.type = new_type;
//   if(comments_data.value?.addon?.likes) comments_data.value.addon.likes = [];
//   if(comments_data.value?.items_comment) comments_data.value.items_comment = [];
//   clearPagination();
//   var dt = await loadData();
//   comments_data.value.items_comment = dt.items_comment;
//   comments_data.value.addon = dt.addon;
// }, {deep: true});
 

useIntersectionObserver(
  el_infinity_scroll,
  ([{isIntersecting}]) => {
    if(isIntersecting){
      console.log('infinity scroll event');        
      if(!is_loading.value){
        console.log('infinity scroll event');        
        if(comments_data.value.cnt > start.value + limit.value){
          start.value = start.value + limit.value;
        }
      }
    }
  },
  { distance: 10 }
)

onUnmounted(() => {
  if(comments_data.value)
    comments_data.value.items_comment = [];
})

if(comments_data.value && props.type == 'diary'){
  // console.log('-----');  
  useHead(
    useNuxtApp().$head.setCommentsForDiary(comments_data.value.items_comment, comments_data.value.cnt_total)
  )
}

onMounted(() => {
  if(import.meta.client){
    if(preloadLnk.value){
      preloadLnk.value = null;
      setTimeout(() => {
        scrollTo({
          top: startofcomments.value.offsetTop - 0,
          behavior: 'smooth'
        })
      }, 1000);
    }
  }
    
})


</script>
   




<style scoped>
  
   
  .settings_comments{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 0rem 1rem;
    flex-wrap: wrap;
    margin-bottom: 1rem;
  }
   
  .toggler{
    display: flex;
    align-items: center;
    gap: 5px;
  }
.empty_comments{
 display: flex;
 align-items: center;
 justify-content: center;
 min-height: 70px;
 width: 100%;
 color: var(--gd-text-gray-color);
}

  @container pb (max-width: 768px) {
    .settings_comments{
      justify-content: space-between;
    }
  }

</style>
